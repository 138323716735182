.card-content {
  display: flex;
  align-items: center; /* Align content vertically */
  padding: 20px;
}

.icon {
  width: 40px; /* Adjust icon size as needed */
  height: 40px;
}
.icon-link {
  margin: 0px 0px 0px 0px;
  position: relative;
  display: flex;
}

.card p,
.card-a {
  text-align: left;
  margin-left: 20px; /* Add spacing between icon and text */
  margin-bottom: 10px; /* Add bottom margin for better separation */
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.card-main {
  width: 85%;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Adjust flex direction to stack elements vertically */
  align-items: center;
  justify-content: center; /* Center content vertically */
  margin: auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
.img-fluid {
  width: 45vh; /* Set your desired width */
  height: 20vh;
  display: block;
  margin: auto;
}
.card-details-container-title {
  margin: auto;
  text-align: center;
  font-size: 34px;
}

.header-container {
  padding: 5px; /* Add padding as needed */
  border-radius: 10px; /* Add border radius if desired */
  display: flexbox; /* Use flexbox for layout */
  z-index: 2;
  top: 0;
  position: fixed;
  width: 85%;
  margin: 0px auto;
  background-color: white;
}

.card {
  width: 95%;
  margin: 15px auto; /* Adjust margin as needed */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  bottom: 0;
}
.container-title-add {
  display: flex;
  margin: auto;
}

.card-details-container-title {
  margin-right: 10px; /* Add margin between title and image */
  text-align: center;
  margin: auto;
}

.add-to-contact-img {
  width: 30px; /* Adjust button width as needed */
  height: 30px; /* Adjust button height as needed */
  margin: auto;
}
.title-con {
  text-align: center;
  margin: 0px 0px 0px 55px;
  padding-right: 30px;
}
.card-details-container {
  width: 80%;
  margin-top: 33vh;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  overflow-y: auto; /* Add vertical scrollbar when content exceeds container height */
}

.container-title-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.add-to-contact-img {
  width: 30px;
  height: 30px;
  margin-left: 15px;
}
.additional-content {
  padding: 10px;
  text-align: center; /* Center the content horizontally */
}

.add-to-contact-btn {
  background-color: #007bff; /* Button background color */
  color: #ffffff; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  border-radius: 5px; /* Add border radius */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.add-to-contact-btn:hover {
  background-color: #0056b3; /* Change background color on hover */
}

@media screen and (max-width: 768px) {
  .card-details-container {
    width: 95%;
    margin-top: vh;
  }
  .card-main {
    width: 100%;
    margin: 0px auto;
  }
  .header-container {
    width: 100%;
  }
  .img-fluid {
    width: 50vh;
    height: 30vh;
  }
  .title-and-button {
    font-size: 22px;
  }
}

@media screen and (max-width: 576px) {
  .card-details-container {
    width: 95%;
    margin-top: 30vh;
  }
  .header-container {
    width: 100%;
  }
  .card-main {
    width: 100%;
    margin: 0px auto;
  }

  .img-fluid {
    width: 35vh;
    height: 15vh;
  }
  .title-and-button {
    font-size: 20px;
  }
}
