/* KotakNeoForm.css */

.neo-Container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Header {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.BackButton {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
}

.HeaderText {
  flex: 1;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.ScrollContainer {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
}

.ProfileContainer {
  display: flex;
  align-items: center;
}

.ProfileImage {
  width: 60px;
  height: 60px;
}

.ProfileName {
  margin-left: 15px;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.Input {
  width: calc(100% - 32px);
  padding: 10px;
  margin: 8px 16px;
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box;
}

.SaveButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 30px;
  padding: 12px;
  background-color: #2d3191;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.SaveButtonContainer:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 30px;
  padding: 12px;
  background-color: green;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.SaveButtonText {
  font-size: 15px;
}
