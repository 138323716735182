.item-container {
  margin-bottom: 4px;
  width: 100%;
}

.pnl-adapter {
  overflow-y: auto;
  max-height: 220px;
  scrollbar-width: none;
}

.progress-bar-container-pnl {
  position: relative;
  height: 40px; /* Adjust height as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  padding: 2px;
  margin: 2px auto;
}

.progress-bar-pnl {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 1;
}

.progress {
  height: 90%;
  border-radius: 5px;
}

.details-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  z-index: 2;
}

.text-strategy {
  font-size: 11px;
  margin-left: 10px;
  min-width: 25%;
  color: black;
}

.text-stock {
  min-width: 25%;
  margin-right: 5%;
  margin-left: 5%;
  text-align: left;
  color: black;
  font-size: 10px;
}

.text-pnl {
  min-width: 10%;
  text-align: left;
  margin: auto;
  font-size: 13px;
}

.broker {
  font-weight: bold;
  font-size: 11px;
  color: black;
}

.status-image {
  width: 20px;
  height: 20px;
  align-items: start;
}

.stopped-status {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.failure-image {
  width: 20px;
  height: 20px;
}

.strategy-name {
  margin: auto;
}

.strategy-name-margin {
  margin-left: 10px;
  margin-right: 300px;
}

.text-strategy {
  font-size: 9px;
  margin-left: 6px;
  min-width: 18%;
}

.text-stock {
  min-width: 18%;
  margin-right: 3%;
  margin-left: 3%;
  font-size: 8px;
}

.text-pnl {
  min-width: 7%;
  font-size: 11px;
}

.progress-bar-container-pnl {
  height: 30px; /* Adjust height for smaller screens */
  padding: 1px;
  margin: 1px auto;
}

@media (max-width: 768px) {
  .text-strategy {
    font-size: 9px;
    margin-left: 6px;
    min-width: 18%;
  }

  .text-stock {
    min-width: 18%;
    margin-right: 3%;
    margin-left: 3%;
    font-size: 8px;
  }

  .text-pnl {
    min-width: 7%;
    font-size: 11px;
  }

  .progress-bar-container-pnl {
    height: 30px; /* Adjust height for smaller screens */
    padding: 1px;
    margin: 1px auto;
  }
}

@media (max-width: 480px) {
  .text-strategy {
    font-size: 8px;
    margin-left: 4px;
    min-width: 16%;
  }

  .text-stock {
    min-width: 16%;
    margin-right: 2%;
    margin-left: 2%;
    font-size: 7px;
  }

  .text-pnl {
    min-width: 6%;
    font-size: 10px;
  }

  .progress-bar-container-pnl {
    height: 25px; /* Adjust height for smaller screens */
    padding: 0.5px;
    margin: 0.5px auto;
  }
}
