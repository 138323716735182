.analysis-home-container {
  overflow-y: auto;
  height: 100vh;
  scrollbar-width: none;
  width: 40%;
  max-width: 40%;
  color: white;
}
.analysis-card {
  width: 90%;
  padding: 8px;
  height: 130px;
  border-radius: 10px;
  background-color: #2d3191;
  background-image: url("/src//images/analysis-bg.jpg"); /* Replace with the correct path to your image */
  background-size: cover; /* This makes the background image cover the entire element */
  background-position: center; /* This centers the background image */
  margin: 8px;
}

.analysis-title {
  font-size: 14px;
  padding: 0px 6px;
}
.analysis-balance {
  font-size: medium;
  padding: 4px 10px;
  margin: 10px;
  font-size: 18px;
}
.analysis-profit {
  font-size: 18px;
  padding: 12px 6px;
  font-style: bold;
  padding: 6p;
}
.analysis-percentage-gain {
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 6px 12px;
  margin: auto;
  color: black;
  font-size: 11px;
}

.analysis-profit-gain-container {
  display: flex;
  justify-content: space-between;
  padding: 2px;
}
