.feature-container-container {
  background-color: white;
  color: black;
  align-items: center;
  text-align: center;
}

.main-home-con {
  display: block;
}
.features-container {
  display: flex;
  margin: auto;
  gap: 10px;
  scroll-behavior: smooth;
  padding: 18px;
  background-color: rgb(255, 255, 255);
}
.feature-card {
  color: black;
  border-radius: 20px;
  width: 33.33%;
  min-height: 200px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 4px 8px 0px rgb(163, 163, 163);
}
.customer-name {
  color: white;
}
.feedback-description {
  color: white;
}
.feature-content {
  flex-grow: 1;
  overflow: auto;
}

.feature-title {
  margin-top: 0; /* Optionally adjust the margin */
  white-space: normal;
  overflow: auto;
  padding: 5px;
  text-overflow: ellipsiss;
  text-align: start;
}

.feature-description {
  font-size: 12px;
  margin-top: 0; /* Optionally adjust the margin */
  white-space: normal;
  overflow: auto;
  padding: 5px;
  text-overflow: ellipsiss;
  text-align: start;
  font-family: sans-serif;
  font-weight: 1;
}

.feature-icon {
  justify-content: start;
  width: 58px;
  height: 58px;
  margin: 16px;
}

.intro-btn-con {
  margin: 10px auto;
  display: flex;
  gap: 35px;
  align-items: center;
}
.intro-con {
  height: 60vh;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/intro-bg.png");
  background-color: #fff; /* Fallback background color */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}
.intro-btn-get-started {
  width: 230px;
  height: 55px;
  margin-top: 34px;
  border: none;
  border-radius: 55px;
  font-size: 21px;
  color: white;
  background-color: green;
}
.intro-btn-get-started:hover {
  z-index: 1;
  color: white;
  background-color: #2d3191;
  cursor: pointer;
}

.intro-btn-watch-trailer {
  width: 230px;
  height: 55px;
  margin-top: 34px;
  border: none;
  border-radius: 55px;
  font-size: 21px;
  color: white;
  background-color: #2d3191;
}
.intro-btn-watch-trailer:hover {
  z-index: 1;
  color: white;
  background-color: green;
  cursor: pointer;
  scale: 1.2;
}

.intro-sub-container {
  letter-spacing: 0.05rem;
  margin: auto;
  width: 100%;
  display: flex;
  scroll-behavior: smooth;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  color: rgb(0, 0, 0);
  height: 60vh;
}

.intro-title {
  font-size: 50px;
  text-align: center;
}
.intro-sub-title {
  font-size: 34px;
}
.getstarted-container {
  background-color: white;
  justify-content: center;
  padding: 10px;
  display: flexbox;
}
.getstarted-title {
  justify-content: center;
  text-align: center;
  color: black;
}
.getstarted-item {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 21px;
  padding-left: 200px;
}

.qr-card {
  width: 210px;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  box-shadow: 0px 2px 8px 0px lightgray;
}

.qr-code {
  width: 129px;
  height: 129px;
}
.download-app {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgb(0, 119, 255);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
}
.download-app:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  background-color: yellow;
  color: black;
}
a {
  text-decoration: none;
}
.playstore {
  margin-left: 10px;
  width: 34px;
  height: 34px;
  padding: 4px;
  align-items: center;
}

.mobile-img {
  border-radius: 11px;
  width: 170px;
  height: 370px;
  background-size: auto;
}

.screenshots {
  width: 100%;
  display: flexbox;
  justify-content: center;
}
.screenshots-item {
  background-color: white;
  display: flex;
  box-shadow: 0 4px 4px 4px lightgray;
  border-radius: 20px;
  margin-top: 10px;
  color: black;
  gap: 20px;
  align-items: center;
  padding: 20px;
  margin: 20px;
}

.screenshots-item:nth-child(even) {
  justify-content: flex-end;
  margin-left: 320px;
  margin-right: 130px;
  align-self: flex-end;
  text-align: end;
}

.screenshots-item:nth-child(odd) {
  justify-content: flex-start;
  margin-right: 320px;
  margin-left: 130px;
}

/* .screenshots-item-center {
  background-color: white;
  display: flex;
  gap: 20px;
  border-radius: 20px;
  justify-content: center;
  width: 33.33%;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 4px 4px lightgray;
} */

.feedback-container {
  background-color: white;
  text-align: center;
}

.feedback-card {
  min-width: 750px;
  background-color: blueviolet;
  display: flexbox;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  text-align: start;
  justify-content: center;
  text-align: center;
}
.start-icon {
  width: 34px;
  height: 34px;
}

.feedback-sub-con {
  display: flex;
  overflow-x: auto;
  width: auto;
  padding-left: 150px;
  scrollbar-width: none;
}

.star-customer-container {
  display: flex;
  align-items: center;
  padding-left: 100px;
  gap: 50%;
}

.title-home {
  color: rgb(0, 43, 161);
  font-size: 34px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .title-home {
    font-size: 24px;
  }
  .main-home-con {
    display: block;
  }
  .intro-btn-get-started {
    width: 150px;
    height: 40px;
    font-size: 20px;
  }
  .intro-btn-watch-trailer {
    width: 150px;
    height: 40px;
    font-size: 20px;
  }

  .feedback-card {
    min-width: 250px;
    flex-direction: column;
    margin: 5px;
  }
  .feature-card {
    width: 100%;
    display: flexbox;
    padding: 15px;
    text-wrap: nowrap;
    margin: 5px;
  }
  .features-container {
    display: block;
  }
  .feature-title {
    font-size: 14px;
  }
  .feature-description {
    font-size: 11px;
    font-family: sans-serif;
    font-weight: 1;
  }

  .start-icon {
    width: 18px;
    height: 18px;
  }
  .feedback-sub-con {
    padding-left: 10px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .star-customer-container {
    gap: 10%;
    padding-left: 0px;
  }

  .screenshots-item:nth-child(even) {
    margin-left: 0px;
    margin-right: 0px;
  }

  .screenshots-item:nth-child(odd) {
    margin-right: 0px;
    margin-left: 0px;
  }
  .intro-btn {
    width: 100px;
    height: 34px;
    margin-top: 10px;
    font-size: 13px;
  }

  .intro-sub-container {
    padding-top: 30px;
    height: auto;
  }
  .intro-title {
    font-size: 21px;
  }
  .intro-sub-title {
    font-size: 24px;
  }
  .intro-con {
    height: 50vh;
    margin: auto;
  }
  .getstarted-item {
    padding-left: 25%;
    justify-content: center;
  }
  .qr-card {
    width: 150px;
    height: 150px;
  }
  .qr-code {
    width: 110px;
    height: 110px;
  }

  .mobile-img {
    width: 119px;
    height: 249px;
  }
}

/* floating buttons */

.floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 44px;
  height: 44px;
  background-color: #2d3192;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  display: flex;
}

.floating-btn:hover {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 44px;
  height: 44px;
  background-color: #2d3192;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  display: flex;
  z-index: 1;
  scale: 1.2;
  box-shadow: 0px 1px 10px 0px #00ffff;
}
.float-1 {
  position: fixed;
  bottom: 69px;
  right: 20px;
  width: 44px;
  height: 44px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  display: flex;
}

.float-1:hover {
  position: fixed;
  bottom: 69px;
  right: 20px;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  z-index: 1;
  scale: 1.2;
  display: flex;
}

.float-2 {
  position: fixed;
  bottom: 118px;
  right: 20px;
  width: 44px;
  height: 44px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  display: flex;
}

.float-2:hover {
  position: fixed;
  bottom: 118px;
  right: 20px;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  z-index: 1;
  scale: 1.2;
  display: flex;
}
.floating-btn-img-nofillter {
  width: 34px;
  height: 34px;
  background-color: transparent;
  cursor: pointer;
}
.floating-btn-img {
  width: 34px;
  height: 34px;
  background-color: transparent;
  cursor: pointer;
  filter: invert(100%) sepia(100%) saturate(100%);
}

.additional-buttons {
  display: none;
}

.additional-buttons.active {
  display: block;
}
.float-con {
  display: flexbox;
}
