.post-container {
  background-color: white;
  text-align: center;
}
.post-container > li {
  text-decoration: none;
}

.post-card > li {
  text-decoration: none;
}

.post-card {
  text-decoration: none;
  border-radius: 15px;
  overflow: auto;
  background-color: wheat;
  padding: 10px;
  padding: 10px;
  width: 80%;
  text-align: center;
  white-space: normal; /* Allow text to wrap */
  max-height: 100px; /* Adjust spacing between cards */
  display: inline-block; /* Display cards in one horizontal line */
  box-sizing: border-box; /* Include padding in card's width calculation */
  transition: transform 0.4s ease-in-out;
  position: relative;
}
.title {
  color: blue;
}
.post-card-title {
  font-size: 15px;
  text-align: center;
  color: black;
  display: flex; /* Change to flex */
  justify-content: space-between; /* Align items with space between them */
  align-items: center; /* Align items vertically */
}

.post-card-title h2 {
  margin: auto; /* Remove default margin */
}

.post-card-title-img {
  width: 20px;
  height: 20px;
}

.comments {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.comments p {
  margin: 2px;
  padding: 2px;
}

.comments img {
  width: 20px;
  height: 20px;
  margin-left: 5px; /* Add some space between the text and image */
}
