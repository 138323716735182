.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
}

.bars-container {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 20px;
}

.bars-content {
  flex: 1;
  min-width: 250px;
  max-width: 500px; /* Limit the max width */
  height: 300px;
  margin: 20px 2px; /* Centering the bars-content */
  padding: 4px 2px;
  border-radius: 5px;
  background-color: white;
}

.title-chart {
  font-size: 16px;
  color: black;
  margin-bottom: 10px;
  text-align: center;
}

.table-chart-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-height: 280px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;
}

.table-chart-header-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 370px;
  width: 100%;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 100px;
}

.table-row {
  display: flex;
  margin-bottom: 2px;
  background-color: white;
  width: 100%;
}

.table-headerCell {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 10px;
  color: black;
  width: 200px;
  min-width: 55px;
  border-color: #9fa2fb;
  border-right: solid 1px #9fa2fb; /* Right border */
}

.table-cell {
  width: 150px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #9fa2fb;
  border-right: solid 1px #9fa2fb; /* Right border */
  padding: 8px;
  font-size: 10px;
  color: black;
  min-width: 55px;
}

.table-headerCell {
  font-weight: bold;
  background-color: #f0f0f0;
}

.table-chart-data-container {
  width: 100%;
  overflow: auto;
}

.table-button-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-between;
}

.time-button {
  background-color: #2d3191;
  color: white;
  padding: 8px 16px;
  text-align: center;
  margin: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.time-button:hover {
  background-color: green;
  transform: scale(1.05);
}

.time-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Media query for devices with a maximum width of 790px */
@media (max-width: 790px) {
  .chart-container {
    display: block;
  }
  .table-headerCell,
  .table-cell {
    min-width: 35px;
    font-size: 5px;
  }
  .table-button-container {
    justify-content: space-around;
  }

  .time-button {
    margin: 2px;
    flex-grow: 1;
    font-size: 10px;
  }
  .title-chart {
    font-size: 14px;
  }
  .chart-container {
    padding: 0px;
    margin: 0px;
  }
  .bars-content {
    width: 100%;
    height: 300px;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .table-chart-header-container {
    width: 90%;
  }
  .table-cell {
    border: 0.5px solid #ccc;
  }
}
