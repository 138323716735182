.dashboard-nav-bar-main-con {
  display: block;
  background-color: #e0e0e0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.dashboard-nav-bar-con {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 50px;
  width: 100%;

  justify-content: space-between;
  align-items: center;
}

.dashboard-nav-title {
  font-size: 30px;
  margin-left: 20px; /* Adjust as needed to give some space from the left edge */
  text-align: left;
}

.image {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 20px;
  margin-right: 20px; /* Adjust as needed to give some space from the right edge */
}
.image:hover {
  scale: 1.5;
  cursor: pointer;
}

.dashboard-analysis-con {
  display: flex;
  flex-direction: row;
  overflow: auto;
}
.dashboard-nav-items {
  display: flex;
  align-items: center; /* Center items vertically */
}

.image {
  width: 20px;
  height: 20px;
  margin-left: 20px; /* Add some space between images */
  margin-right: 2px;
}

.user-name {
  margin-left: 10px; /* Add some space between the last image and the user name */
  font-size: 16px;
  color: #333;
  margin-right: 100px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .dashboard-analysis-con {
    display: block;
  }
  .user-name {
    font-size: 11px;
    margin-right: 5px;
  }
  .image {
    margin-right: 0px;
  }
}

/* Example of HTML structure for reference */
