.extra-tab-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  max-height: 100vh;
  margin: auto;
  border-color: #9fa2fb;
  border-right: solid 1px #9fa2fb; /* Right border */
  box-shadow: 0 0 1px rgba(205, 198, 255, 0.3);
}

.dashboard-menu-nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: white;
}
.extra-tab-button {
  cursor: pointer;
  color: #2d3191;
  border: none;
  display: flex;
  flex-direction: row;
  height: 48px;
  border-radius: 12px;
  margin: 5px;
  transition: background-color 0.3s, color 0.3s;
  padding: 5px;
}

.extra-tab-button.active {
  color: green;
  /* background-color: #d9ffbe; */
}

.extra-tab-button.active .selected {
  width: 4px;
  height: 70%;
  background-color: green;
  margin-right: 10px;
  border-radius: 5px;
  margin: auto;
}
.extra-tab-button:hover {
  transform: scale(1.1); /* Scales the image up by 10% */
}

.extra-tab-button.active:hover {
  /* background-color: #2d3191;
  color: white; */

  transform: scale(1.1); /* Scales the image up by 10% */
}

.extra-tab-content {
  margin: auto;
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
  background-color: white;
  scrollbar-width: none;
  margin-bottom: 50px;
  flex: 3.8;
  padding: 8px;
}

.right-side-charts {
  overflow-y: auto;
  height: 100vh;
  scrollbar-width: none;
  flex: 1.8;
}

.extra-tab-icon {
  width: 20px;
  height: 20px;
  margin: auto 11px;
  border-radius: 20px;
}

.extra-tab-title {
  font-size: 14px;
  text-align: left;
  margin: auto 0px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.expand-menu-img {
  width: 45px;
  height: 45px;
  justify-content: center;
  background-color: #e0e0e0;
  margin: 10px auto;
  padding: 15px;
  border-radius: 30px;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Offset, blur, color */
  transition: transform 0.3s ease; /* Smooth scaling transition */
}

.expand-menu-img:hover {
  transform: scale(1.1); /* Scales the image up by 10% */
}

.dashboard-expand {
  background-color: #e0e0e0;
  margin: auto;
}

@media (max-width: 768px) {
  .expand-menu-img {
    width: 30px;
    height: 30px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow for mobile */
  }

  .expand-menu-img:hover {
    transform: scale(1.05); /* Less scaling for smaller screens */
  }
  .right-side-charts {
    overflow: unset;
  }
  .extra-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 5px;
    min-width: 16%;
  }

  .extra-tab-button.active .selected {
    width: 30px;
    height: 5px;
    background-color: green;
    margin-right: 0;
    margin-top: 4px;
    border-radius: 5px;
    position: absolute; /* Positioning at the top */
    top: 0; /* Align at the top */
  }
  .extra-tab-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    height: auto;
    max-height: none;
    border-right: none; /* Remove the right border */
    border-top: solid 1px #9fa2fb; /* Add top border */
    background-color: white;
    z-index: 190;
    min-width: 100%;
  }

  .extra-tab-content {
    margin: 0px;
    display: block;
    width: 100%;
    bottom: 0px;
    margin: auto;
  }
  .extra-tab-content {
    padding: 2px;
  }
}

.notifications-window {
  position: absolute;
  top: 60px; /* Adjust based on where you want the notifications to appear */
  right: 20px; /* Adjust based on your layout */
  width: 300px; /* Adjust width as needed */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  max-height: 400px; /* Optional: Limit the height */
  overflow-y: auto; /* Scroll if content exceeds max height */
}

.notification-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px;
}

.notification-card:last-child {
  margin-bottom: 0;
}

.notification-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-message {
  margin-bottom: 5px;
}

.notification-time {
  font-size: 0.8em;
  color: #888;
  margin-bottom: 5px;
}

.notification-priority {
  font-size: 0.8em;
  color: red; /* Highlight priority in red */
}
