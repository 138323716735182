.pt {
  margin: 0px;
  background-color: white;
  padding: 40px;
}
.pricingTable-title {
  text-align: center;
  font-size: 40px;
  color: black;
  padding: 20px;
  letter-spacing: 0.04em;
}

.pricingTable-subtitle {
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  letter-spacing: 0.04em;
  margin-bottom: 30px;
  padding: 20px;
  margin: 20px;
}
.pricingTable-firstTable {
  list-style: none;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
}
.pricingTable-firstTable_table {
  vertical-align: middle;
  width: 31%;
  background-color: #fff;
  display: inline-block;
  padding: 0px 30px 40px;
  text-align: center;
  max-width: 320px;
  box-shadow: 0 2px 8px 2px darkgray;
  transition: all 0.3s ease;
  border-radius: 23px;
  position: relative;
}

.pricingTable-firstTable_table:hover {
  transform: scale(1.08);
}

.pricingTable-firstTable_table:not(:last-of-type) {
  margin-right: 3.5%;
}
.pricingTable-firstTable_table:nth-of-type(2):before {
  content: "Most Popular";
  position: absolute;
  color: white;
  display: block;
  background-color: #3bbdee;
  text-align: center;
  right: 15px;
  top: -25px;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 0.5em;
  padding-top: 22px;
  text-transform: uppercase;
  letter-spacing: 0.13em;
  transition: all 0.5s ease;
}
.pricingTable-firstTable_table:nth-of-type(2) {
  position: relative;
}
.discount-badge {
  font-size: 11px;
  color: black;
  position: absolute;
  text-align: center;
  top: -15px;
  border-radius: 10%;
  width: 100px;
  height: 35px;
  padding-top: 10px;
  background-color: rgb(255, 183, 0);
  margin-left: -60px;
}
.discount-badge-trail {
  font-size: 11px;
  color: black;
  position: absolute;
  text-align: center;
  top: -15px;
  border-radius: 10%;
  width: 100px;
  height: 35px;
  padding-top: 10px;
  background-color: rgb(255, 183, 0);
  margin-left: -60px;
  margin-top: 37px;
}

.discount-badge-span {
  font-size: 12px;
}

.pricingTable-firstTable_table:nth-of-type(2):hover:before {
  transform: rotate(360deg);
}
.pricingTable-firstTable_table__header {
  font-size: 1.6em;
  padding: 10px 0px;
  border-bottom: 2px solid #ebedec;
  letter-spacing: 0.03em;
  color: black;
  margin-top: 15px;
}
.pricingTable-firstTable_table__pricing {
  font-size: 2.5em;
  padding: 8px 0px;
  border-bottom: 2px solid #ebedec;
  color: black;
  line-height: 0.7;
}

.pricingTable-firstTable_table__pricing span:first-of-type {
  font-size: 0.35em;
  vertical-align: top;
  letter-spacing: 0.15em;
}

.pricingTable-firstTable_table__pricing span:last-of-type {
  vertical-align: bottom;
  font-size: 0.3em;
  letter-spacing: 0.04em;
  padding-left: 0.2em;
}

.pricingTable-firstTable_table__options {
  list-style: none;
  padding: 15px;
  font-size: 0.9em;
  text-align: left;
  border-bottom: 2px solid #ebedec;
}
.pricingTable-firstTable_table__options > li {
  padding: 8px 0px;
  font-size: 1.1em;
}
.pricingTable-firstTable_table__getstart {
  color: white;
  border: 0;
  background-color: #71ce73;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0px 3px 0px 0px rgba(102, 172, 100, 1);
  letter-spacing: 0.07em;
  transition: all 0.4s ease;
}
.pricingTable-firstTable_table__getstart:hover {
  transform: translateY(-10px);
  box-shadow: 0px 40px 29px -19px rgba(102, 172, 100, 0.9);
}
.pricingTable-firstTable_table__getstart:active {
  box-shadow: inset 0 0 10px 1px rgba(102, 165, 100, 1),
    0px 40px 29px -19px rgba(102, 172, 100, 0.95);
  transform: scale(0.95) translateY(-9px);
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 100%;
  background-color: #f0f4f7;
  color: #717787;
}
.pricingTable-firstTable_table__options > li:before {
  content: "✓";
  display: inline-flex;
  margin-right: 15px;
  color: white;
  background-color: #74ce6a;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 0.8em;
  padding: 2px;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .pricingTable > .pricingTable-subtitle {
    margin-bottom: 30px;
  }
  .pricingTable-firstTable_table > * {
    display: block;
    float: none;
  }
  .pricingTable-firstTable_table:nth-of-type(2):before {
    font-size: 0.8em;
  }
  .pricingTable-firstTable_table__header {
    float: none;
    width: 100%;
    font-size: 1.8em;
  }
  .pricingTable-firstTable_table__pricing {
    float: none;
    width: 100%;
    font-size: 1.5em;
    margin-bottom: 10px;
    padding: 10px;
  }
  .pricingTable-firstTable_table__options {
    font-size: 1.3em;
    margin-right: none;
    margin-bottom: 10px;
  }
  .pricingTable-firstTable_table__getstart {
    font-size: 1em;
    width: 50%;
    margin: 10px auto;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table {
    display: block;
    width: 90%;
    margin: 0 auto;
    max-width: 90%;
    margin-bottom: 20px;
    padding: 10px;
    padding-left: 20px;
  }
  .pricingTable-title {
    font-size: 18px;
    color: black;
  }
  .pricingTable-firstTable_table > * {
    display: flexbox;
  }
  .pricingTable-firstTable_table:after {
    display: block;
    clear: both;
  }
  .pricingTable-firstTable_table:hover {
    transform: none;
  }
  .pricingTable-firstTable_table:not(:last-of-type) {
    margin-right: auto;
  }
  .pricingTable-firstTable_table:nth-of-type(2) h1 {
    padding-top: 8%;
  }
  .pricingTable-firstTable_table:nth-of-type(2):before {
    font-size: 0.6em;
    left: 10px;
    width: 45px;
    height: 45px;
    top: -10px;
    padding-top: 13px;
  }
  .discount-badge {
    width: 80px;
    right: -20px;
    top: -10px;
    padding-top: 10px;
  }
  .discount-badge-trail {
    width: 80px;
    right: -20px;
    top: -10px;
    padding-top: 12px;
    margin-top: 37px;
  }
  .pricingTable-firstTable_table__header {
    padding: 0px;
    border-bottom: none;
    font-size: 2.2rem;
  }
  .pricingTable-firstTable_table__pricing {
    border-bottom: none;
    text-align: center;
    padding: 10px;
    font-size: 3rem;
  }
  .pricingTable-firstTable_table__options {
    border-bottom: none;
    padding: 0;
    margin-right: 10%;
    font-size: 0.85em;
  }
  .pricingTable-firstTable_table__options > li {
    text-align: left;
    font-size: 1.2em;
  }
  .pricingTable-firstTable_table__options > li:before {
    width: 12px;
    height: 12px;
  }
  .pricingTable-firstTable_table__getstart {
    margin-top: 0;
  }
  .pricingTable-firstTable_table__getstart:hover {
    transform: none;
    box-shadow: none;
  }
  .pricingTable-firstTable_table__getstart:active {
    transform: scale(0.95) translateY(0);
    box-shadow: none;
  }
}

@media screen and (max-width: 988px) {
  .pricingTable-firstTable_table:nth-of-type(2):before {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__header {
    font-size: 2.5rem;
  }
  .pricingTable-firstTable_table__pricing span:last-of-type {
    font-size: 0.25em;
  }
  .pricingTable-firstTable_table__pricing span:first-of-type {
    font-size: 0.3em;
  }
  .pricingTable-firstTable_table__options {
    font-size: 0.85em;
  }
  .pricingTable-firstTable_table__options > li:before {
    width: 14px;
    height: 14px;
    padding: 1.5px;
  }
  .pricingTable-firstTable_table__getstart {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 610px) {
  .pricingTable-firstTable_table__header {
    font-size: 2.3em;
  }
  .pricingTable-firstTable_table__pricing {
    font-size: 3em;
  }
  .pricingTable-firstTable_table__options {
    font-size: 0.7em;
    margin-right: 8%;
  }
  .pricingTable-firstTable_table__options > li {
    padding: 5px 0;
    font-size: 1em;
  }
  .pricingTable-firstTable_table__getstart {
    font-size: 0.9em;
    padding: 10px;
  }
  .discount-badge-span {
    font-size: 11px;
  }
}

@media screen and (max-width: 960px) {
  body {
    font-size: 80%;
  }
}

@media screen and (max-width: 776px) {
  body {
    font-size: 70%;
  }
}

@media screen and (max-width: 496px) {
  body {
    font-size: 50%;
  }
}

@media screen and (max-width: 320px) {
  body {
    font-size: 40%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
