/* Updated CSS */
.card--container-faqs {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: block;
}

.card-header {
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  justify-content: space-between;
  align-items: center;
  background-color: wheat;
  text-align: center;
  margin: 10px;
}

.card-body {
  background-color: azure;
  border-top: 1px solid #ddd;
  margin: 10px;
}

.expand-icon img {
  width: 20px; /* Adjust the size of the image */
  height: 20px; /* Adjust the size of the image */
  margin-left: 50px;
  align-items: center;
  padding: 1px;
}
