.support-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.support-link {
  padding: 12px 24px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.support-link:hover {
  background-color: #0056b3;
}
