.faq--container {
  /* background-color: white; */
  text-align: center;
  padding: 8px;
  display: grid;
  background-color: white;
  text-align: center;
}
.faq--container > h1 {
  font-size: 30px;
  padding: 8px;
  margin: 8px;
}
.card-faq > h4 {
  font-size: 20px;
  padding: 8px;
  margin: 8px;
  color: black;
}
.card-faq > p {
  font-size: 15px;
  margin: 0 auto;
  padding: 0px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  max-width: 100%; /* Ensure description does not overflow card */
  max-height: 90px; /* Maximum height of paragraph */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}
.card-faq {
  max-width: 400px;
  min-width: 200px;
  min-height: 200px;
  max-height: 200px;
  border-radius: 10px;
  padding: 8px;
  margin: auto;
  text-align: center;
  align-self: center;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.card--container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between; /* Distribute space between cards */
  margin-bottom: 5px; /* Add margin at the bottom */
}

.viewall {
  text-align: center;
  text-decoration: none;
}

.viewall--btn {
  text-align: center;
  margin: 10px auto;
  color: black;
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .faq--container {
    justify-content: center;
  }
}
