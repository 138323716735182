/* RunStrategyActivity.css */

.container-run {
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.input-container {
  margin-right: 4px;
  margin-left: 4px;
  margin-bottom: 2px;
}

.input-container input,
.input-container select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-container select {
  appearance: none; /* Remove default arrow in Chrome/Safari */
  -webkit-appearance: none; /* Remove default arrow in Firefox */
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  background-size: 16px auto;
  padding-right: 30px; /* Adjust according to icon size */
}
.subheading {
  color: black;
  text-align: left;
  margin-left: 10px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
}
.run-button-container {
  text-align: center;
  margin-top: 10px;
}

.run-button-container button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #2d3191;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.run-button-container button:hover {
  background-color: #45a049;
}

.run-button-container button.clicked {
  background-color: #45a049;
}
