/* Common styles */
.navbar {
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-logo {
  cursor: pointer;
  text-decoration: none;
  font-size: 34px;
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: flex-start;
  width: 70px;
  height: 70px;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: 5px;
}

.nav-links.active {
  color: white;
  background-color: #2d3191;
  border-radius: 40px;
  padding: 8px 13px;
  margin: auto;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
  background-color: white;
  height: 100%;
}

.nav-links {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 5px 10px;
  background-color: white;
  color: #000;
}

.nav-links:hover {
  border-bottom: 4px solid white;
  transition: all 0.5s ease-out;
}

.menu-icon {
  display: none; /* Initially hide the menu icon */
}

.menu-img {
  display: none; /* Initially hide the menu image */
}

@media screen and (max-width: 960px) {
  .navbar-logo {
    justify-content: center;
    padding-left: 0px;
  }

  .logo {
    justify-content: flex-start;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: white;
    z-index: 1;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: white;
    background-color: #2d3192;
  }

  .menu-icon {
    display: block; /* Show menu icon on smaller screens */
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    margin: auto;
    background-color: white;
    z-index: 2; /* Ensure menu icon is above navbar content */
  }

  .menu-img {
    margin-right: 0px;
    width: 30px; /* Adjust width of the menu icon */
    height: 35px; /* Adjust height of the menu icon */
    display: block; /* Ensure the menu image is displayed */
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 14px 20px;
    border: 1px solid #000;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    transition: 250ms;
  }
}

@media screen and (min-width: 961px) {
  .nav-links-mobile {
    display: none;
  }

  .nav-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: none;
    position: relative;
    top: initial;
    left: initial;
    opacity: 1;
    transition: none;
  }

  .nav-links {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: white;
    color: #000;
    transition: none;
  }

  .nav-links:hover {
    color: #fff;
    background-color: #2d3191;
    border-radius: 40px;
  }

  .nav-menu.active {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .menu-icon {
    display: none; /* Hide menu icon on larger screens */
  }

  .menu-img {
    display: none; /* Hide the menu image on larger screens */
  }
}
