:root {
  --primary: #000000;
}
.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  color: white;
  cursor: pointer;
  border: none;
}
.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}
.btn--outline {
  padding: 8px 20px;
  border: 1px solid var(--primary);
  color: white;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
  background-color: black;
  color: white;
}
.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  color: #000000;
  background-color: white;
}
.btn--medium:hover,
.btn--outline:hover,
.btn--primary:hover,
btn:hover,
.btn--large:hover {
  background: #6083ff;
  color: #ffffff;
  border-radius: 10px;
}
