.pricing-comparison-container {
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.plans {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.plan-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33.33% - 20px);
  background-color: #fff;
}

.plan-card.highlighted {
  border-color: #ff4d4d;
}

.plan-details {
  margin-bottom: 20px;
}

.plan-details h3 {
  margin-top: 0;
}

.plan-details ul {
  padding: 0;
}

.plan-details ul li {
  list-style-type: disc;
  margin-left: 20px;
}

.view-details-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-details-btn:hover {
  background-color: #0056b3;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 20% auto;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.plan-details > h3 {
  text-align: center;
}
.benifits {
  padding: 8px;
  margin: 8px;
}
