.login-container {
  width: 350px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px; /* Increased border-radius for rounded corners */
}

.login-heading {
  text-align: center;
  color: #2d3191;
  padding: 4px;
  margin: 8px;
}

.login-methods {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.login-method-button {
  padding: 10px 20px;
  border: none;
  background-color: #bdbdbd; /* Light gray background */
  cursor: pointer;
  outline: none;
  font-size: 16px;
  border-radius: 20px; /* Increased border-radius for rounded corners */
}

.login-method-button.active {
  background-color: #0084ff; /* Blue color for active button */
  color: #fff;
}

.input-group {
  margin-bottom: 20px;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

.send-otp-button,
.login-button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff; /* Blue color for buttons */
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.send-otp-button:hover,
.login-method-button:hover,
.login-button:hover {
  background-color: #89b1dc; /* Darker blue on hover */
  color: black;
}
.do-not-have-account {
  display: flex;
  flex-direction: row;
  margin: 8px;
  text-align: right;
  margin-right: 0px;
}

.do-not-have-account p {
  margin-right: 4px;
}
