.button-container {
  margin: 0 auto;
  padding: 20px 40px; /* Increase padding for larger size */
  background-color: blue;
  color: white;
  border-radius: 10px; /* Increase border radius */
  text-align: center; /* Center text horizontally */
  align-self: center;
  width: auto;
  display: flex;
}

.button-icon {
  width: 48px; /* Increase width of the icon */
  height: 48px; /* Increase height of the icon */
  vertical-align: middle;
  margin-right: 12px; /* Increase margin for larger size */
}

.button-text {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  font-size: 24px; /* Increase font size for larger size */
}

@media (max-width: 992px) {
  .button-container {
    padding: 10px 20px; /* Adjust padding for small screens */
  }

  .button-icon {
    width: 52px; /* Adjust width of the icon for small screens */
    height: 52px; /* Adjust height of the icon for small screens */
    margin-right: 8px; /* Adjust margin for small screens */
  }

  .button-text {
    font-size: 20px; /* Adjust font size for small screens */
  }
}

@media (max-width: 576px) {
  .button-container {
    padding: 8px 16px; /* Adjust padding for extra small screens */
  }

  .button-icon {
    width: 50px; /* Adjust width of the icon for extra small screens */
    height: 50px; /* Adjust height of the icon for extra small screens */
    margin-right: 6px; /* Adjust margin for extra small screens */
  }

  .button-text {
    font-size: 20px; /* Adjust font size for extra small screens */
  }
}
.parent-container {
  width: 85%;
  margin: auto;
  margin-top: 5%;
  justify-content: center; /* Center child elements horizontally */
  align-items: center; /* Center child elements vertically */
  height: 100vh; /* Make the parent container full height of the viewport */
}
