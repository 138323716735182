.about-us-content {
  text-align: left;
  padding: 10px;
  margin: 10px;
}
.about-us-content > p {
  padding: 10px;
  font-style: italic;
}
.about-us-heading {
  text-align: center;
  padding: 10px;
  margin: 10px;
}
