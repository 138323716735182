.broker-container {
  background-color: white;
  border-radius: 15px;
  margin: 8px auto;
  color: black;
  padding: 15px;
  width: 80%;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow values as needed */
}

.broker-title {
  text-align: center;
  margin: auto;
  font-size: 13px;
}
.save {
  background-color: red;
  padding: 8px;
  border-radius: 50px;
  width: 80px;
  text-align: center;
}

/* MovableDialog.css */
.movable-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.full-movable-dialog {
  position: absolute;
  background: white;
  border-radius: 8px;
  width: 85%;
  height: 100%;
  cursor: move;
  overflow-y: auto;

  border-top: solid 2px #9fa2fb; /* Top border */
  border-right: solid 2p2x #9fa2fb; /* Right border */
  border-bottom: solid 2px #9fa2fb; /* Bottom border */
  border-left: solid 2px #9fa2fb; /* Left border */
}

.movable-dialog {
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 500px;
  height: 100%;
  cursor: move;
  overflow-y: auto;
}

.movable-dialog-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  cursor: move;
}

.movable-dialog-close {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.movable-dialog-content {
  padding: 10px;
  cursor: default;
}
.broker-title-dailog {
  text-align: center;
  color: black;
  font-size: 24px;
}
.broker-description-dailog {
  text-align: center;
  font-size: 18px;
  padding: 4px;
}
@media (max-width: 768px) {
  .movable-dialog {
    width: 80%;
    height: 85%;
  }
  .movable-dialog-overlay {
    position: fixed;
    margin: auto;
    height: 90%;
    z-index: 50;
  }
  .full-movable-dialog {
    position: absolute;
    background: white;
    border-radius: 8px;
    width: 85%;
    height: 85%;
  }
}
