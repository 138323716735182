/* Global Container */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5; /* Light background for better contrast */
}

/* Signup Form */
.signup-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Header */
.signup-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Signup Methods */
.signup-methods {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

/* Buttons */
.signup-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #0084ff;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.signup-button.active,
.signup-button:hover {
  background-color: green;
}

/* Input Group */
.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

/* Dialog */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure dialog is on top */
}

/* Dialog Content */
.dialog-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  width: 300px; /* Ensure consistent dialog width */
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-gif {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1976d2;
  border-right: 4px solid #1976d2;
  border-bottom: 4px solid #1976d2;
  border-left: 4px solid #1976d2;
  border-radius: 50%;
  width: 100px; /* Increased size for better visibility */
  height: 100px;
}

/* Success Dialog */
.success-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure dialog is on top */
}

.success-dialog-content {
  background: #fff;
  padding: 20px;
  border-radius: 21px;
  text-align: center;
  max-width: 400px; /* Small width for dialog */
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-image {
  width: 233px; /* Adjust size of success image */
  height: 144px;
  margin-bottom: 15px;
}

.success-dialog-content h2 {
  font-size: 18px;
  color: #4caf50; /* Green color for success */
  margin-bottom: 10px;
}

.success-dialog-content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.success-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.success-button:hover {
  background-color: #388e3c; /* Darker green on hover */
}
.resend-otp {
  margin-right: 0;
  width: 100%;
  text-align: right;
  cursor: pointer;
  margin-top: 8px;
  color: red;
}
/* Keyframes for Spinner Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
