.feedback-container {
  padding: 20px;
  color: #2d3191;
  text-align: center;
}

.title-hme-feed {
  margin-bottom: 20px;
  color: rgb(0, 43, 161);
  font-size: 34px;
  font-family: Arial, Helvetica, sans-serif;
}

.feedback-sub-con {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 10px;
  scroll-behavior: smooth;
  transition-property: all;
}

.feedback-card {
  flex: 0 0 auto;
  min-width: 300px;
  background-color: blueviolet;
  color: white;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px;

  transform: translateX(-50px); /* Initial position for horizontal movement */
  opacity: 0; /* Initial opacity */
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out; /* Transition effect */
}

.feedback-card.show {
  transform: translateX(0); /* Final position */
  opacity: 1; /* Final opacity */
}

.star-customer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 100px;
  gap: 50%;
}

.start-icon {
  width: 20px;
  height: 20px;
}

.customer-name {
  margin-left: 10px;
  font-weight: bold;
  color: white;
}

.feedback-description h3 {
  margin-top: 0;
}

.feedback-description p {
  margin-bottom: 0;
  color: white;
}

@media only screen and (max-width: 768px) {
  .feedback-card {
    width: 220px;
    flex-direction: column;
    margin: 5px;
  }

  .start-icon {
    width: 18px;
    height: 18px;
  }

  .feedback-sub-con {
    padding-left: 10px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .star-customer-container {
    gap: 10%;
    padding-left: 0px;
  }
}
