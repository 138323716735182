.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.privacy-policy-container h2 {
  color: #555;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.privacy-policy-container p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Add more styles as needed */
