.container {
  padding: 20px;
  display: flexbox;
  text-align: center;
  background-color: #ed7d31;
  border-radius: 20px;
}

.card-register {
  text-align: center;
  padding: 20px;
  max-width: 70%;
  min-width: 30%;
  min-height: 200px;
  border-radius: 10px;
  background-color: white;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.title-hme {
  color: rgb(0, 43, 161);
  font-size: 34px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  text-align: center;
}

.detsils {
  text-align: center;
  color: black;
  font-size: 18px;
  font-family: sans-serif;
  width: 85%;
  padding: 30px;
  text-align: start;
  margin: 5px auto;
}
/* Media queries for smaller screen sizes */
@media screen and (max-width: 768px) {
  .card-register {
    max-width: 90%; /* Adjust maximum width */
    min-width: 80%; /* Adjust minimum width */
    min-height: 150px; /* Adjust minimum height */
    padding: 15px; /* Adjust padding */
  }
  .title-hme {
    font-size: 21px;
  }
  .detsils {
    font-size: 14px;
    text-align: start;
    width: 100%;
    margin: 0px;
    padding: 10px;
  }
}

/* Media queries for even smaller screen sizes */
@media screen and (max-width: 480px) {
  .card-register {
    max-width: 95%; /* Further adjust maximum width */
    min-width: 90%; /* Further adjust minimum width */
    min-height: 100px; /* Further adjust minimum height */
    padding: 10px; /* Further adjust padding */
  }
}

.register-input {
  width: 100%;
  margin-bottom: 15px; /* Add some space between form inputs */
  border: 2px solid #3498db; /* Add border */
  border-radius: 5px; /* Add border radius */
  padding: 10px; /* Add padding */
  transition: border-color 0.3s ease; /* Add transition for smooth effect */
}

/* Style for the form inputs when focused */
.register-input:focus {
  border-color: #2980b9; /* Change border color when focused */
  outline: none; /* Remove default focus outline */
}
.submit-button {
  background-color: #242424;
  color: white;
  text-align: center;
  margin: 8px;
}
