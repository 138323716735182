.dashboard-home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 100vh; /* Set height to 100% of the viewport height */
  scrollbar-width: none;
}
.strategy-card {
  height: 200px;
  padding: 10px;
  background-color: var(--running-card-color);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow on all sides */
  position: relative;
}
.strategy-list-con {
  width: 100%;
  min-width: 100%;
}

.strategy-list {
  display: grid;
  margin-bottom: 13px;
  grid-template-columns: repeat(3, 33.33%);
  min-width: 60%;
}
.parent-container {
  margin: 0px auto; /* Make sure the parent container takes full viewport height */
}

.create-strategy {
  background: white;
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column; /* Arrange content inside the h4 in a column */
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow on all sides */
  text-align: center; /* Center align text */
}

.strategy-card-inner {
  padding: 5px;
}
.container-name-card {
  display: flex;
  justify-content: le;
  align-items: center;
  margin-top: 20px;
}

.img-chart {
  width: 30px;
  height: 30px;
  color: #2d3191;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.strategy-name {
  margin-left: 10px;
  color: #2d3191;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.below-strategy-name {
  margin-top: 10px;
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}
.section-title-btn-strategy {
  background-color: #686dff; /* Default background color */
  color: white;
  padding: 8px 8px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 8px 13px;
  border-radius: 21px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px 8px;
}

.section-title-btn-strategy.active {
  background-color: #2f39ff; /* Change to this color when active */
}

.tag {
  margin: 5px; /* Margin around each tag */
  padding: 4px 8px; /* Padding inside each tag */
  background-color: #343434;
  color: white;
  font-size: 12px;
  display: flexbox;
  border-radius: 10px; /* Rounded corners */
}

.lin-bottom {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.capital-info {
  margin-left: 20px;
  margin-top: 20px;
}

.capital-info-text {
  color: gray;
  margin: 3px 5px 3px 5px;
}

.stop-strategy {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 15px;
}

.broker-name {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #2d3191; /* Example color for round_broker_name_bg */
  color: white;
  padding: 8px;
  font-size: 13px;
  border-top-right-radius: 20px;
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
  scrollbar-width: none;
}

.dialog-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.dialog-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.dialog-buttons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.dialog-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog-button:hover {
  background-color: green;
}

.dialog-button-confirm {
  background-color: gray;
}

.dialog-button-confirm:hover {
  background-color: #c82333;
}

.tab-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.button-pnl-con {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  margin: 0px 21px;
  border-radius: 8px;
}

/* 
.tab-button.active {
  background-color: #4caf50;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tab-button:hover {
  background-color: #2d3191;
  color: white;
} 

.tab-button.active:hover {
  background-color: #45a049;
}*/

/* styles.css */

.outerContainer {
  background-color: white;
  display: flex;
  margin-top: auto;
}

.innerContainer {
  flex: 1;
  background-color: white;
  border-radius: 8px;
  overflow-y: auto;
  min-height: 200px;
  min-width: 100%;
  max-height: 200px;
  border-color: #9fa2fb;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 10px rgba(205, 198, 255, 0.3);
}

.single-pnl-container {
  width: 90%;
  margin: auto;
  flex: 1;
  background-color: white;
  border-radius: 8px;
  overflow-y: auto;
  padding: 4px;
  min-height: 200px;
  min-width: 100%;
  max-height: 200px;
  border-color: #9fa2fb;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 10px rgba(205, 198, 255, 0.3);
  visibility: hidden;
  display: none;
}

.orders-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px; /* Space between grid items */
  padding: 16px;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .orders-list-container {
    display: flex;
    flex-direction: row; /* Ensure items are in a single row */
    overflow-x: auto; /* Allow horizontal scrolling */
    gap: 16px; /* Space between items */
  }

  .orders-list-container > div {
    flex: 0 0 auto; /* Prevent items from growing or shrinking */
    min-width: 200px; /* Ensure items have a minimum width */
    margin-right: 16px; /* Space between items */
  }
}

/* ProfileActivity.css */
.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to top, #2d3191, green);
  max-width: 70vh; /* You might want to use max-width: 70vw for better responsiveness */
  color: white;
  font-family: Arial, sans-serif;
  border-radius: 20px;
  margin: auto;
  height: 85vh; /* 80% of the viewport height */
}
.user-title {
  font-size: 18px;
}
.profile-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
/* Mobile View */
@media (max-width: 768px) {
  .create-strategy {
    width: 60%;
    height: 100px;
  }
  .profile-name {
    font-size: 11px;
  }
  .profile-container {
    max-width: 90vw; /* Adjust width for mobile, 90% of viewport width */
    height: 80vh; /* Ensure height is 80% of viewport height on mobile */
    /* If you need a different height on smaller screens, you can set it explicitly */
    height: 75%; /* Optional: increase height if needed for mobile */
    overflow-y: auto;
    scrollbar-width: none;
    width: 300px;
  }
  .user-title {
    font-size: 11px;
  }
  .icon-filtered {
    width: 20px;
    height: 20px;
  }
}

.linear-layout {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; /* Adjust max-width to control the container width */
}

.horizontal-layout {
  display: flex;
  align-items: center;
}
.horizontal-layout-pointer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.back-btn {
  width: 30px; /* Adjust icon size as needed */
  height: 30px;
}

.profile-title {
  flex: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  margin-top: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Adjust divider color and opacity */
  margin: 8px 0;
}

.profile-image-container {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px; /* Adjust profile image size as needed */
  height: 100px;
  border-radius: 50%; /* Ensure the image is circular */
  margin-right: 10px;
  padding: 10px;
}

.edit-icon {
  width: 30px; /* Adjust edit icon size as needed */
  height: 30px;
}

.profile-details {
  flex: 1;
}

.profile-email {
  font-size: 16px;
  margin: 0;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 8px;
  color: black;
}

.icon-filtered {
  width: 20px; /* Adjust icon size as needed */
  height: 20px;
  margin: 10px;
  filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
}
.icon {
  width: 30px; /* Adjust icon size as needed */
  height: 30px;
  margin-right: 10px;
}

.dark-theme-text {
  font-size: 16px;
  margin-left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: auto;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider.round:before {
  transform: translateX(26px);
}

.version-text {
  padding: 10px;
}
/*  may be duplicate this tru */

.tab-button {
  background-color: white; /* Default background color */
  color: white;
  padding: 8px 13px;
  border: none;
  cursor: pointer;
  font-size: 18px;

  border-radius: 34px;
  color: black;
}
.tab-button:nth-child(2) {
  background-color: #e0e0e0; /* Default background color for the second button */
}

.current-running-orders {
  display: block;
}

@media (max-width: 768px) {
  .parent-container {
    height: auto;
  }
  .single-pnl-container {
    background-color: white;
    border-radius: 8px;
    overflow-y: auto;
    padding: 4px;
    min-height: 200px;
    max-height: 200px;
    border-color: #9fa2fb;
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 10px rgba(205, 198, 255, 0.3);
    visibility: visible;
    display: block;
  }
  .innerContainer {
    display: none;
  }
  .section-title-btn-strategy {
    font-size: 11px;
    padding: 11px 8px;
    margin: 2px;
  }
  .dashboard-home-container {
    display: block;
    position: fixed;
  }

  .tab-container {
    flex-direction: row; /* Stack buttons vertically */
    gap: 0%;
  }

  .tab-button {
    width: 100%; /* Make buttons full width */
    margin: 0px; /* Add margin between buttons */
    border-radius: 21px;
    font-size: 11px;
    background-color: gray;
    color: white;
    padding: 11px 0px;
  }

  /* Ensure only one button is active */
  .tab-button {
    background-color: gray; /* Default background color for all buttons */
  }

  .tab-button.active {
    background-color: #2d3191; /* Background color for active button */
  }
  .tab-button:nth-child(2) {
    background-color: gray; /* Default background color for the second button */
  }

  .tab-button:nth-child(2).active {
    background-color: green; /* Background color for the second button when active */
  }
  .outerContainer {
    display: flex;
    justify-content: space-between;
    margin-right: auto;
  }
  .strategy-list {
    display: flex;
    overflow-x: auto;
    margin: 0px;
  }
}

.strategy-status-container {
  display: flex;
  flex-direction: row; /* Ensure items are in a single row */
  flex-wrap: nowrap; /* Prevent items from wrapping */
  overflow-x: auto; /* Allow horizontal scrolling if items overflow */
  max-width: 100%; /* Full width of the container */
  margin: auto;
  padding: 5px; /* Optional: add padding around the container */
}

.strategy-status-container > div {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 4px auto;
  padding: 5px;
  box-sizing: border-box; /* Include padding in width calculations */
}

.strategy-status-container > div img {
  width: 20px;
  height: 20px;
  margin-right: 8px; /* Space between image and text */
}

.strategy-status-container > div p {
  font-size: 14px; /* Font size for readability */
  color: black;
  margin: 0; /* Remove default margin for alignment */
}

.strategy-status-container > select {
  margin: 4px;
  padding: 5px;
  flex: 0 0 auto; /* Prevent the select element from growing or shrinking */
  font-size: 11px;
}

/* Responsive styles for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .strategy-status-container {
    padding: 2px; /* Adjust padding for smaller screens */
  }

  .strategy-status-container > div {
    flex: 0 0 auto; /* Ensure items do not shrink or grow */
    margin: 0px auto;
    padding: 4px 1px;
  }
  .strategy-status-container > div img {
    margin-right: 2px;
  }

  .strategy-status-container > div p {
    font-size: 9px;
    margin: 0px;
    padding: 0px;
  }
  .strategy-status-container > select {
    margin: 0px;
    padding: 0px;
    flex: 0 0 auto; /* Prevent the select element from growing or shrinking */
    font-size: 8px;

    width: 50px;
  }
}
