.verify-phone-container {
  width: 45%;
  height: 80vh;
  margin: 10vh auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */
  padding: 10px;
  border-radius: 21px;
}
.resend-otp {
  margin-right: 0;
  width: 100%;
  text-align: right;
  cursor: pointer;
  margin-top: 8px;
  color: red;
}
