.date-component {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.move-container {
  display: flex;
  flex-direction: row;
  left: 10px;
  right: 10px;
  border-radius: 50px;
  padding: 4px;
  bottom: 50px;
}
.orderfragment-inner {
  display: "flex";
  margin: "auto";
  justify-content: "space-between";
}

.left-button,
.right-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
}

.left-button {
  background-color: #f44336; /* Red color for move one day back */
  color: white;
}

.right-button {
  background-color: #4caf50; /* Green color for move one day forward */
  color: white;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(25%, 1fr)
  ); /* Adjust as per your layout */
  gap: 10px; /* Adjust gap between grid items */
  padding: 10px;
}

.order-fragment-inner {
  display: flex;
  justify-content: space-between; /* Distribute space between items */
  align-items: flex-start; /* Align items to the top */
  width: 100%; /* Ensure the container takes up full width */
  padding: 10px; /* Optional: Add padding for spacing */
}

.order-fragment-inner .left {
  align-self: flex-start; /* Align to the top */
}

.order-fragment-inner .right {
  align-self: flex-start; /* Align to the top */
  margin-left: auto; /* Push the right element to the end */
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    margin: auto;
    margin-bottom: 100px;
  }

  .left-button,
  .right-button {
    font-size: 11px;
  }
}
