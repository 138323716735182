.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.success-icon {
  color: #28a745; /* Green color */
  font-size: 5rem;
}

.success-heading {
  margin-top: 20px;
  font-size: 2rem;
}

.success-message {
  margin-top: 10px;
  font-size: 1.2rem;
}
