.order-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status {
  background-color: #999999;
  color: #ffffff;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
}
.img-chart {
  width: 30px;
  height: 30px;
}
.icon-strategy {
  display: flex;
  text-align: center;
}
.strategy {
  color: #2d3191;
  padding: 8px;
}
.stop {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.strategy-name {
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
  margin: 10px;
}
.order-item-container {
  display: block;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
  background-color: #ffffff;
}
.txt-capital {
  margin-left: 15px;
  margin-top: 15px;
  color: #999999;
}

.txt-capital-price {
  margin-left: 15px;
  margin-top: 3px;
  color: #999999;
}
.txt-mock {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: green;
  padding: 4px;
  color: white;
  border-top-right-radius: 8px;
  font-size: 11px;
}

.order-cap-broker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: auto;
  margin-top: 10px;
}
.stock-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: auto;
}

.order-broker {
  text-align: right;
  color: black;
}

.cap {
  padding: 2px;
  margin-left: 10px;
  color: black;
}

.stock-name {
  font-size: 12px;
  background-color: #dddddd;
  padding: 5px;
  color: black;
  border-radius: 4px;
}
.order-broker-name {
  font-size: 12px;
  background-color: #dddddd;
  padding: 8px;
  color: black;
  border-radius: 8px;
}
.order-details {
  margin-right: auto;
  font-size: 12px;
  background-color: #2d3191;
  padding: 10px;
  color: white;
  border-radius: 18px;
  margin: 4px;
}

.txt-time {
  position: absolute;
  top: 20px;
  right: 5px;
  font-size: 12px;
  color: #999999;
}

.txt-view-order {
  margin-left: 10px;
  margin-top: 30px;
  margin-right: 20px;
  background-color: #007bff;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.ic-pause {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 28px;
  bottom: 20px;
  display: none; /* Initially hidden */
}

.order-item:hover .ic-pause {
  display: block; /* Display on hover */
}
