.blog-details-container {
  width: 80%;
  background-color: wheat;
  border-radius: 20px;
  padding: 10px;
  margin: 10px auto;
}

.post-title {
  text-align: center;
  font-size: 24px;
  color: black;
  margin: 10px;
}

.post-description {
  text-align: center;
  color: black;
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 20px;
}

.comment-count {
  font-weight: bold;
  padding: 5px;
  margin: 5px;
  color: black;
}

.comment-list {
  list-style-type: none;
  padding: 0;
}

.comment-item {
  margin-bottom: 5px;
  margin-left: 50px;
  color: black;
  list-style-type: decimal-leading-zero; /* Use disc marker */
}

.comment-input {
  width: 50%;
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  margin: 10px;
}

.submit-comment-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 10px;
  margin-left: 15px;
  cursor: pointer;
}

.submit-comment-btn:hover {
  background-color: #0056b3;
}
