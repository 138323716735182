.strategy-item {
  height: 200px;
  margin: 4px;
  padding: 1px;
  margin-left: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Simulates elevation */
  border-radius: 14px;
  background-color: #ffffff; /* Replace with your desired background color */
}

.strategy-name-icon {
  display: flex;
  align-items: center; /* Vertically centers the items */
  margin: 21px;
}

.imgchart {
  width: 30px;
  height: 30px;
}

.txtname {
  color: #2d3191;
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.txtdetails {
  color: #2d3191;
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 21px;
  font-size: 12px;
}

.linbottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.relative-layout {
  flex: 1;
  display: flex;
  align-items: center;
}

.txtpercent_ll,
.popular_ll {
  display: flex;
  align-items: center;
  background-color: #ffffff; /* Replace with your desired background color */
  padding: 3px 10px;
  border-radius: 20px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.txtpercent {
  color: #808080;
  margin-left: 5px;
}

.progress-bar {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Additional styling for progress bar if needed */
}
.title {
  color: black;
  font-size: 24px;
  margin-bottom: 8px;
  text-align: center;
}
@media (max-width: 768px) {
  .strategy-item {
    height: 200px;
    margin: 4px;
    padding: 1px;
    width: 300px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Simulates elevation */
    border-radius: 14px;
    background-color: #ffffff;
  }
}
