/* OrderDetails.css */
.order-details-main-container {
  display: flex;
  flex-direction: row;
  scrollbar-width: none;
  /* Adding borders */
}

.order-details-container {
  width: 40%;
  padding: 20px;
  box-sizing: border-box;
  scrollbar-width: none;
}

.graph-details-container {
  width: 80%;
  max-height: 100px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.strategy-section,
.status-section,
.status-running-section,
.capital-section,
.broker-section,
.time-section,
.strategy-id-section {
  display: flex;
  align-items: center;
  margin: auto;
}

.black-text {
  color: black;
}

.stock-section {
  margin: 5px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.separator {
  height: 1px;
  background-color: #ddd;
  margin: 5px 0;
}

.profit-loss-section {
  display: flex;
  justify-content: space-between;
}

.profit-item {
  color: white;
  background-color: #2d3191;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for elevation */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 48%; /* Adjust as needed to fit two items side by side */
  padding: 16px; /* Adds padding inside the card */
  border-radius: 8px; /* Rounds the corners of the card */
  margin: 8px; /* Adds some space between the cards */
  transition: transform 0.2s, box-shadow 0.2s; /* Adds smooth transition for hover effect */
}

.ltp-section {
  display: flex;
  margin: auto;
  flex-direction: row;
}

.ltp-item {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for elevation */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  flex-basis: 48%; /* Adds padding inside the card */
  border-radius: 8px; /* Rounds the corners of the card */
  margin: 8px; /* Adds some space between the cards */
  transition: transform 0.2s, box-shadow 0.2s; /* Adds smooth transition for hover effect */
}

.icon {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-right: 8px; /* Space between icon and text */
}

.ltp-text {
  font-size: 16px; /* Adjust text size as needed */
}

.executed-order-details-container {
  margin: auto;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: space-around;
}

.executed-order-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.executed-order-details-status-icon {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

.executed-order-details-text-name {
  margin-left: 33px;
  margin: auto;
}

.executed-order-details-text-price {
  margin: auto;
}

.executed-order-details-time-icon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.executed-order-details-text-time {
  color: black;
  margin-left: auto;
  margin-right: 8px;
}

.stop-strategy-button {
  background-color: red;
  color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.non-stop-strategy-button {
  background-color: gray;
  color: black;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
.graph-container {
  width: 100%;
  margin: auto;
  padding: 0px;
}

@media (max-width: 768px) {
  .order-details-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    scrollbar-width: none;
  }

  .order-details-container {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    max-height: 80%;
  }

  .graph-details-container {
    width: 100%;
    padding: 2px;
    margin: auto;
    margin: 0;
  }

  .executed-order-details-container {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    overflow-y: auto; /* Allow scrolling if needed */
  }

  .executed-order-details {
    width: 100%;
    padding: 8px;
  }

  .executed-order-details-status-icon {
    width: 24px;
    height: 24px;
    margin: 0;
  }

  .separator {
    width: 100%;
  }

  .profit-loss-section,
  .ltp-section {
    flex-direction: column;
    width: 100%;
  }

  .profit-item,
  .ltp-item {
    width: 100%;
    margin: 8px 0; /* Add margin between items */
  }
}
