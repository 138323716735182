/* FailurePage.css */

.failure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.failure-icon {
  color: red; /* You can adjust the color as needed */
  font-size: 5rem;
  margin-bottom: 20px;
}

.failure-heading {
  margin-top: 20px;
  font-size: 2rem;
}

.failure-message {
  margin-top: 10px;
  font-size: 1.2rem;
}
